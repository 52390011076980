/* global google */
import {
  GEOCODE_SUGGESTION_FETCH_SUCCESS,
  PLACES_SUGGESTION_FETCH_SUCCESS,
  SET_SEARCH_SUGGESTION_TERM,
  SET_SEARCH_SELECTION,
  CLEAR_SEARCH_SUGGESTIONS,
  SET_SEARCH_MARKER,
  SET_SEARCH_FOCUSED,
  SET_SELECTED_LAYER_ELEMENT
} from '../constants/action-types';
import { LOCALITY_INFO } from '../constants/google-maps';
import { setCenter, setZoom } from './map-actions';


const placesApi = new google.maps.places.AutocompleteService();
const geocoder = new google.maps.Geocoder();

const fetchGeocodeSuggestionsSuccess = suggestions => ({
  type: GEOCODE_SUGGESTION_FETCH_SUCCESS,
  suggestions
});

const fetchPlacesSuggestionsSuccess = suggestions => ({
  type: PLACES_SUGGESTION_FETCH_SUCCESS,
  suggestions
});

export const fetchGeocodeSuggestions = (searchValue) => dispatch => {
  // Google places API has added intersections. We previously used the geocoder to find intersections, but it is no longer needed.
  dispatch(fetchGeocodeSuggestionsSuccess([]));
  // geocoder.geocode({ address: `${searchValue},${LOCALITY_INFO}` }, (georesult, geostatus) => {
  //   if (geostatus === 'OK') {
  //     dispatch(fetchGeocodeSuggestionsSuccess(georesult.map(suggestion => ({
  //       label: suggestion.formatted_address,
  //       mainText: suggestion.formatted_address,
  //       value: suggestion.formatted_address,
  //       place_id: suggestion.place_id,
  //       location: {
  //         lat: suggestion.geometry.location.lat(),
  //         lng: suggestion.geometry.location.lng()
  //       },
  //       types: suggestion.types,
  //       type: 'address'
  //     }))));
  //   }
  //   if (geostatus === 'ZERO_RESULTS') {
  //     dispatch(fetchGeocodeSuggestionsSuccess([]));
  //   }
  // });
  placesApi.getQueryPredictions({ input: `${searchValue},${LOCALITY_INFO}` }, (georesult, geostatus) => {
    if (geostatus === 'OK') {
      dispatch(fetchPlacesSuggestionsSuccess(georesult.map(suggestion => ({
        label: suggestion.description,
        mainText: suggestion.structured_formatting.main_text,
        secondaryText: suggestion.structured_formatting.secondary_text,
        value: suggestion.description,
        place_id: suggestion.place_id,
        type: 'address'
      }))));
    }
    if (geostatus === 'ZERO_RESULTS') {
      dispatch(fetchPlacesSuggestionsSuccess([]));
    }
  });
};

export const setSearchTermAction = (searchTerm) => ({
  type: SET_SEARCH_SUGGESTION_TERM,
  searchTerm
});

export const setSearchTerm = searchValue => (dispatch) => {
  dispatch(setSearchTermAction(searchValue));
};

export const clearSearchSuggestions = () => ({
  type: CLEAR_SEARCH_SUGGESTIONS
});

const setSearchSelectionSuccess = location => ({
  type: SET_SEARCH_SELECTION,
  location
});

export const setSelectedLayerElement = element => ({
  type: SET_SELECTED_LAYER_ELEMENT,
  element
});

const doSetSearchMarker = visible => ({
  type: SET_SEARCH_MARKER,
  visible
});

const doSetSearchFocused = focused => ({
  type: SET_SEARCH_FOCUSED,
  focused
});

export const setSearchSelection = (searchValue) => dispatch => {
  if (searchValue.type === 'address') {
    if (searchValue.location) {
      const location = {
        label: searchValue.mainText,
        value: searchValue.value,
        position: searchValue.location
      };
      dispatch(setCenter({lat: location.position.lat, lng: location.position.lng}));
      dispatch(setZoom(17));
      dispatch(setSearchSelectionSuccess(location));
      dispatch(doSetSearchMarker(true));
      dispatch(doSetSearchFocused(false));
    } else if (searchValue.place_id) {
      geocoder.geocode({placeId: searchValue.place_id}, (results, status) => {
        if (status === 'OK') {
          const result = results[0];
          const location = {
            label: searchValue.mainText,
            value: searchValue.value,
            position: {
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng()
            }
          };
          dispatch(setCenter({lat: location.position.lat, lng: location.position.lng}));
          dispatch(setZoom(17));
          dispatch(setSearchSelectionSuccess(location));
          dispatch(doSetSearchMarker(true));
          dispatch(doSetSearchFocused(false));
        }
      });
    }
  } else {
    dispatch(setSelectedLayerElement(searchValue));
    dispatch(doSetSearchFocused(false));
  }
};

export const clearSearchSelection = () => dispatch => {
  dispatch(setSearchSelectionSuccess(null));
  dispatch(setSelectedLayerElement(null));
};

export const setSearchMarker = visible => dispatch => {
  dispatch(doSetSearchMarker(visible));
};

export const setSearchFocused = focused => dispatch => {
  dispatch(doSetSearchFocused(focused));
};
